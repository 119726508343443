import styles from './NotificationDetails.module.css'
import { React, useRef, useEffect, useState } from 'react'
import { IFButton, IFText, IFDialog } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'
import { formatDate } from '../../Utils/StringFunctions'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'

const NotificationDetails = ({
  row,
  editingAdminRole,
  editNotification,
  editNotificationRequestState,
  fetchNotifications,
  onEdit,
}) => {
  const { t } = useTranslation()
  const canEditNotification = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATIONS,
  )
  const cancelNotificationRef = useRef()

  const cancelNotificationHandler = () => {
    const notification = {
      status: InfinityEnums.NotificationStatus.CANCELLED,
    }
    editNotification(row?.original?._id, notification)
  }

  const NotificationDetailsItems = [
    {
      key: 'englishTitle',
      value: row?.original?.title?.en || '-',
      translationKey: 'NotificationDetails.EnglishTitle',
    },
    {
      key: 'arabicTitle',
      value: row?.original?.title?.ar || '-',
      translationKey: 'NotificationDetails.ArabicTitle',
    },
    {
      key: 'englishBody',
      value: row?.original?.message?.en
        ? row.original.message.en.length > 65
          ? row.original.message.en.substring(0, 65) + '...'
          : row.original.message.en
        : '-',
      translationKey: 'NotificationDetails.EnglishMessage',
    },
    {
      key: 'arabichBody',
      value: row?.original?.message?.ar
        ? row.original.message.ar.length > 65
          ? row.original.message.ar.substring(0, 65) + '...'
          : row.original.message.ar
        : '-',
      translationKey: 'NotificationDetails.ArabicBody',
    },
    {
      key: 'url',
      value: row?.original?.redirectUrl || '-',
      translationKey: 'NotificationDetails.Url',
    },
    {
      key: 'groups',
      value:
        row?.original?.notificationGroups?.length > 0
          ? row.original.notificationGroups.join(', ')
          : '-',
      translationKey: 'NotificationDetails.Groups',
    },
    {
      key: 'createdAt',
      value: formatDate(row?.original?.createdAt) || '-',
      translationKey: 'NotificationDetails.CreatedAt',
    },
    {
      key: 'scheduledAt',
      value: formatDate(row?.original?.scheduledAt) || '-',
      translationKey: 'NotificationDetails.ScheduledAt',
    },
  ]

  return (
    <div
      className={styles.NotificationDetails}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.Line}>
        <div
          style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
          className={styles.Divider}
        />
        <div
          style={{ background: Colors.DividerColor }}
          className={styles.Circle}
        />
      </div>
      <div className={styles.Details}>
        <div className={styles.NotificationsDetailsPanel}>
          <IFText className={styles.NotificationTitle}>
            {t('NotificationDetails.Title')}
          </IFText>
          <div className={styles.AllDetails}>
            {NotificationDetailsItems.map((item) => (
              <div className={styles.Detail} key={item.key}>
                <div className={styles.LabelTitle}>
                  <IFText
                    className={styles.DetailText}
                    style={{ color: Colors.DetailPanel }}
                  >
                    {t(item.translationKey)}
                  </IFText>
                </div>
                <div className={styles.LabelValue}>
                  <IFText className={styles.DetailText}>{item.value}</IFText>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {canEditNotification &&
        row?.original?.status ===
          InfinityEnums.NotificationStatus.SCHEDULED && (
          <div className={styles.ButtonContainer}>
            <IFButton
              color={Colors.primary}
              size="sm"
              text={t('PushNotificationsPanel.editButton')}
              isLoading={false}
              isFill={false}
              onClick={onEdit}
            />

            <IFButton
              color={Colors.primary}
              size="sm"
              text={t('PushNotificationsPanel.cancelButton')}
              isLoading={false}
              isFill={false}
              onClick={(e) => {
                e.stopPropagation()
                cancelNotificationRef.current.show()
              }}
            />
          </div>
        )}

      <IFDialog
        ref={cancelNotificationRef}
        style={{ margin: '15em' }}
        open={false}
        title={t('PushNotificationsPanel.dialogTitle')}
        bodyText={t('PushNotificationsPanel.cancelText')}
        buttonAcceptonClick={cancelNotificationHandler}
        buttonCancelColor={Colors.AdminListDeleteModalCancel}
        buttonAcceptColor={Colors.AdminListDeleteModalDelete}
        buttonAcceptText={t('PushNotificationsPanel.confirmCancel')}
        buttonClassName={styles.Button}
        isLoading={editNotificationRequestState === RequestState.LOADING}
        dismissOnAccept={true}
      />
    </div>
  )
}

NotificationDetails.propTypes = {
  row: PropTypes.object,
  editingAdminRole: PropTypes.array,
  fetchNotifications: PropTypes.func,
  onEdit: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return {
    editNotification: (id, payload) =>
      dispatch(NotificationActions.editNotification(id, payload)),
  }
}

const mapStateToProps = (state) => ({
  editNotificationRequestState:
    NotificationSelectors.getEditNotificationRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetails)
