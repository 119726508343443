import { React, useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { IFText, IFTable, IFButton, IFDialog } from 'Components'
import Styles from './NotificationGroupsList.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Colors from 'Theme/Colors'
import { Skeleton } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import EditIcon from '@mui/icons-material/Edit'
import { CreateNotificationGroupModal } from 'Containers'
import { Box, IconButton, Tooltip } from '@mui/material'

function queryToReadableText(query, row, maxLength = 70) {
  let parsedQuery
  try {
    parsedQuery = typeof query === 'string' ? JSON.parse(query) : query
  } catch (error) {
    return 'Invalid query'
  }

  if (!parsedQuery || Object.keys(parsedQuery).length === 0) {
    return 'Everyone'
  }

  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1)

  const formatDate = (dateString) => {
    const date = new Date(dateString)
    if (isNaN(date.getTime())) return dateString
    return date.toISOString().split('T')[0]
  }

  const parseCondition = (key, value, negate = false) => {
    const formattedKey = capitalizeFirstLetter(key)

    if (typeof value === 'object' && value !== null) {
      if (value.$regex) {
        const regexValue = value.$regex
          .replace(/\\\\/g, '')
          .replace(/[\/\\]/g, '')
        if (regexValue.startsWith('^')) {
          return `${formattedKey}: ${
            negate ? 'Does not start' : 'Starts'
          } with "${regexValue.slice(1)}"`
        } else if (regexValue.endsWith('$')) {
          return `${formattedKey}: ${
            negate ? 'Does not end' : 'Ends'
          } with "${regexValue.slice(0, -1)}"`
        } else {
          return `${formattedKey}: ${
            negate ? 'Does not contain' : 'Contains'
          } "${regexValue}"`
        }
      }

      if (value.$ne) {
        return `${formattedKey}: ${negate ? 'Is' : 'Is not'} "${value.$ne}"`
      }

      const conditions = []
      if (value.$gt) conditions.push(`After ${formatDate(value.$gt)}`)
      if (value.$gte) conditions.push(`On or After ${formatDate(value.$gte)}`)
      if (value.$lt) conditions.push(`Before ${formatDate(value.$lt)}`)
      if (value.$lte) conditions.push(`On or Before ${formatDate(value.$lte)}`)

      if (conditions.length > 0) {
        return `${formattedKey}: ${conditions.join(' and ')}`
      }
    }

    return `${formattedKey}: ${negate ? 'Not ' : ''}${value}`
  }

  const parseGroup = (group, operator) => {
    if (!Array.isArray(group)) return ''

    return `(${group
      .map((condition) =>
        typeof condition === 'object' && condition !== null
          ? Object.entries(condition)
              .map(([subKey, subValue]) =>
                typeof subValue === 'object' &&
                subValue !== null &&
                ['$and', '$or', '$not'].includes(subKey)
                  ? parseLogicalOperator(subKey, subValue)
                  : parseCondition(subKey, subValue),
              )
              .join(` ${operator} `)
          : condition,
      )
      .join(` ${operator} `)})`
  }

  const parseLogicalOperator = (operator, value) => {
    if (operator === '$not') {
      const notConditions = Object.entries(value).map(([subKey, subValue]) => {
        if (['$and', '$or'].includes(subKey)) {
          return `NOT ${parseGroup(subValue, subKey.toUpperCase())}`
        }
        return parseCondition(subKey, subValue, true)
      })
      return `(${notConditions.join(' AND ')})`
    } else {
      return parseGroup(value, operator.toUpperCase())
    }
  }

  let queryParts = []

  Object.entries(parsedQuery).forEach(([key, value]) => {
    if (['$or', '$and', '$not'].includes(key)) {
      queryParts.push(parseLogicalOperator(key, value))
    } else {
      queryParts.push(parseCondition(key, value))
    }
  })

  let result = queryParts.join(', ')
  if (result.length > maxLength) {
    return result.substring(0, maxLength) + '...'
  }
  result = result.replace(/\$/g, '')
  return result
}
const NotificationGroupsList = ({
  tablesHeight,
  groups,
  fetchGroups,
  paginationOffset,
  fetchGroupsRequestState,
  groupsFilter,
  admin,
  editingAdminRole,
  deleteGroup,
  deleteGroupRequestState,
}) => {
  const { t } = useTranslation()

  const canEditGroups = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATION_GROUPS,
  )
  const confirmDeleteDialog = useRef()
  const createNotificationGroupModal = useRef()
  const [data, setData] = useState()

  const loadMoreData = () => {
    if (
      fetchGroupsRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return
    fetchGroups(groupsFilter, paginationOffset, failedRequest)
  }
  const nameValue = useRef()

  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchGroupsRequestState === RequestState.ERROR_0_NETWORK ||
      fetchGroupsRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchGroupsRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchGroupsRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchGroupsRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchGroupsRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchGroupsRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchGroupsRequestState])
  const handleRetry = () => {
    fetchGroups(groupsFilter, paginationOffset)
  }
  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )

  const [selectedRow, setSelectedRow] = useState()
  useEffect(() => {
    if (deleteGroupRequestState === RequestState.SUCCEEDED) {
      confirmDeleteDialog?.current?.dismiss()
      setSelectedRow()
    }
  }, [deleteGroupRequestState])

  const groupsTableColumns = useMemo(() => {
    const columns = [
      {
        accessorKey: 'name',
        size: 160,
        enableEditing: false,
        muiEditTextFieldProps: () => {
          return {
            type: 'text',
            value: nameValue?.current,
            onChange: (event) => {
              nameValue.current = event.target.value
            },
            sx: {
              '& .MuiInputBase-root:hover::before': {
                borderBottom: `1px solid ${Colors.primary} !important`,
              },
              '& .MuiInputBase-root.Mui-focused::after': {
                borderBottom: `2px solid ${Colors.primary} !important`,
              },
            },
          }
        },
        header:
          fetchGroupsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.GroupsTableColumns.Name')
          ),
        Cell: ({ row }) => {
          const truncatedName =
            row?.original?.groupName?.length > 25
              ? `${row.original.groupName.substring(0, 25)}...`
              : row.original.groupName

          return row.index < groups.length ? (
            <IFText>{truncatedName}</IFText>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'count',
        size: 240,
        enableEditing: false,
        header:
          fetchGroupsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.GroupsTableColumns.Count')
          ),
        Cell: ({ row }) => {
          return row.index < groups.length ? (
            <IFText>{row.original.userCount}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'query',
        size: 240,
        header:
          fetchGroupsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.GroupsTableColumns.Query')
          ),
        Cell: ({ row }) => {
          return row.index < groups.length ? (
            <IFText>{queryToReadableText(row.original.groupQuery, row)}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ]

    if (admin?.registeredCountries?.length > 1) {
      columns.push({
        accessorKey: 'country',
        size: 240,
        enableEditing: false,
        header:
          fetchGroupsRequestState === RequestState.LOADING ? (
            <Skeleton
              variant="text"
              width="6.875rem"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.GroupsTableColumns.Country')
          ),
        Cell: ({ row }) => {
          return row.index < groups.length ? (
            <IFText>{row.original.groupCountry}</IFText>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      })
    }

    return columns
  }, [
    groups,
    fetchGroupsRequestState,
    failedRequest,
    nameValue,
    groupsFilter,
    admin,
  ])

  const renderRowActions = ({ row }) => {
    if (Object.keys(row.original).length > 0) {
      const send = row
      return (
        <Box>
          <div className={Styles.EditIcon}>
            <Tooltip title={t('AdminList.Edit')}>
              <IconButton
                onClick={(row) => {
                  setData(send)
                  createNotificationGroupModal.current.show(send)
                }}
              >
                <EditIcon sx={{ color: Colors.gray }} />
              </IconButton>
            </Tooltip>
          </div>
        </Box>
      )
    }

    if (failedRequest) {
      return (
        <IFButton
          size="sm"
          color={Colors.red}
          text={t('IFTable.TryAgain')}
          onClick={handleRetry}
        />
      )
    }
  }

  return (
    <div className={Styles.tables}>
      {((fetchGroupsRequestState !== RequestState.SUCCEEDED &&
        !paginationOffset) ||
        groups) && (
        <IFTable
          columns={groupsTableColumns}
          data={groups}
          enableEditing={true}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
          paginationOffset={paginationOffset}
          filters={groupsFilter}
          showSkeleton={
            (fetchGroupsRequestState === RequestState.LOADING &&
              paginationOffset === 0) ||
            (fetchGroupsRequestState !== RequestState.SUCCEEDED &&
              !paginationOffset)
          }
          tableMaxHeight={tablesHeight}
          tableSetHeight={'100%'}
          tableRequestState={fetchGroupsRequestState}
          renderRowActions={canEditGroups ? renderRowActions : undefined}
          editDisplayMode={'modal'}
        />
      )}
      <IFDialog
        ref={confirmDeleteDialog}
        open={false}
        maxWidth={'xs'}
        fullWidth={true}
        title={t('PushNotificationsPanel.groupDialogTitle')}
        bodyText={t('PushNotificationsPanel.deleteGroupText')}
        buttonAcceptonClick={() => {
          deleteGroup(selectedRow)
        }}
        buttonCancelColor={Colors.UserPageCancel}
        buttonAcceptColor={Colors.UserPageDeactivateButton}
        buttonAcceptText={t('PushNotificationsPanel.confirmCancel')}
      />
      <CreateNotificationGroupModal
        title={t('PushNotificationsPanel.PanelTitleGroupEdit')}
        ref={createNotificationGroupModal}
        admin={admin}
        isEdit={true}
        adminRole={editingAdminRole}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchGroups: (filter, offset, shouldShowError) =>
      dispatch(
        NotificationActions.fetchGroups(filter, offset, shouldShowError),
      ),
    deleteGroup: (id) => dispatch(NotificationActions.deleteGroup(id)),
  }
}

const mapStateToProps = (state) => ({
  fetchGroupsRequestState:
    NotificationSelectors.getFetchGroupsRequestState(state),
  groups: NotificationSelectors.getGroups(state),
  paginationOffset: NotificationSelectors.getGroupsPaginationOffset(state),
  groupsFilter: NotificationSelectors.getGroupsFilter(state),
  admin: AuthSelectors.getUser(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  deleteGroupRequestState:
    NotificationSelectors.getDeleteGroupRequestState(state),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationGroupsList)
