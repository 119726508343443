import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchNotificationGroups: null,
  fetchNotificationGroupsLoading: null,
  fetchNotificationGroupsSuccess: ['notificationGroups'],
  fetchNotificationGroupsError: ['error'],

  fetchNotifications: ['filter', 'offset', 'shouldShowError'],
  fetchNotificationsLoading: null,
  fetchNotificationsSuccess: ['offset', 'notifications', 'nextOffset'],
  fetchNotificationsError: ['error'],

  editNotification: ['id', 'payload'],
  editNotificationLoading: null,
  editNotificationSuccess: ['notification'],
  editNotificationFail: ['error'],

  scheduleNotification: ['payload'],
  scheduleNotificationLoading: null,
  scheduleNotificationSuccess: ['notification'],
  scheduleNotificationFail: ['error'],

  clearNotifications: null,

  fetchNotificationsCountError: ['error'],
  fetchNotificationsCountLoading: null,
  fetchNotificationsCountSuccess: ['count'],
  fetchNotificationsCount: ['filter'],

  fetchGroupsCountError: ['error'],
  fetchGroupsCountLoading: null,
  fetchGroupsCountSuccess: ['count'],
  fetchGroupsCount: ['filter'],

  setCountryFilterNoti: ['admin'],
  setCheckFilter: ['names'],
  setNotificationsFilter: ['newFilter'],

  fetchGroupsLoading: null,
  fetchGroupsSuccess: ['offset', 'groups', 'nextOffset'],
  fetchGroupsError: ['error'],
  fetchGroups: ['filter', 'offset', 'shouldShowError'],

  editGroup: ['id', 'payload'],
  editGroupLoading: null,
  editGroupSuccess: ['group'],
  editGroupFail: ['error'],

  deleteGroup: ['id'],
  deleteGroupLoading: null,
  deleteGroupSuccess: ['id'],
  deleteGroupFail: ['error'],

  createGroup: ['payload'],
  createGroupSuccess: ['group'],
  createGroupLoading: null,
  createGroupFail: ['error'],

  fetchQueryCount: ['payload'],
  fetchQueryCountSuccess: ['count'],
  fetchQueryCountLoading: null,
  fetchQueryCountError: ['error'],
  
  setGroupsFilter: ['newFilter'],
  clearGroups: null,
  setInvalidId: ['bool'],
})

export const NotificationTypes = Types
export default Creators
