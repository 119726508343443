import { React, useMemo, useCallback, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { IFText, IFTable, IFButton, NotificationDetails } from 'Components'
import Styles from './NotificationsList.module.css'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Colors from 'Theme/Colors'
import { formatDate } from '../../Utils/StringFunctions'
import { Skeleton } from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AuthSelectors from 'Stores/Auth/Selectors'
import { NotificationModal } from 'Containers'

const NotificationsList = ({
  notifications,
  notificationsFilter,
  paginationOffset,
  fetchNotificationsRequestState,
  fetchNotifications,
  tablesHeight,
  editingAdminRole,
  editNotificationRequestState,
}) => {
  const { t } = useTranslation()
  const notificationModalRef = useRef()
  const canViewNotifications = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
  )
  useEffect(() => {
    if (editNotificationRequestState === RequestState.SUCCEEDED) {
      notificationModalRef.current.dismiss()
    }
  }, [editNotificationRequestState])

  const loadMoreData = () => {
    if (
      (canViewNotifications &&
        fetchNotificationsRequestState === RequestState.LOADING) ||
      paginationOffset === null
    )
      return

    fetchNotifications(notificationsFilter, paginationOffset, failedRequest)
  }

  const [failedRequest, setFailedRequest] = useState(false)
  useEffect(() => {
    if (
      fetchNotificationsRequestState === RequestState.ERROR_0_NETWORK ||
      fetchNotificationsRequestState === RequestState.ERROR_400_OCCURRED ||
      fetchNotificationsRequestState === RequestState.ERROR_401_OCCURRED ||
      fetchNotificationsRequestState === RequestState.ERROR_403_OCCURRED ||
      fetchNotificationsRequestState === RequestState.ERROR_409_OCCURRED ||
      fetchNotificationsRequestState === RequestState.ERROR_UNKNOWN_OCCURRED
    )
      setFailedRequest(true)
    if (fetchNotificationsRequestState === RequestState.SUCCEEDED)
      setFailedRequest(false)
  }, [fetchNotificationsRequestState])
  const handleRetry = () => {
    if (canViewNotifications)
      fetchNotifications(notificationsFilter, paginationOffset)
  }

  const notifcationsTableColumns = useMemo(
    () => [
      {
        accessorKey: 'title',
        header:
          fetchNotificationsRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.NotificationsTableColumns.Title')
          ),
        Cell: ({ row }) => {
          return row.index < notifications.length ? (
            <div className={Styles.ColumnTitle}>
              <ExpandMoreIcon
                className={Styles.ExpandMoreIcon}
                style={{
                  transform: row.getIsExpanded()
                    ? 'rotate(0deg)'
                    : 'rotate(-90deg)',
                }}
              />
              <div className={Styles.NotificationTitle}>
                <IFText className={Styles.TitleText}>
                  {row?.original?.title?.en || ''}
                </IFText>
              </div>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <ReportIcon
                width={28}
                height={28}
                style={{ color: Colors.red }}
              />
              <IFText>{t('IFTable.TryAgainText')}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'notificationId',
        header:
          fetchNotificationsRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.NotificationsTableColumns.Id')
          ),
        Cell: ({ row }) => {
          return row.index < notifications.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText> {row?.original?._id}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'notificationStatus',
        header:
          fetchNotificationsRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.NotificationsTableColumns.Status')
          ),
        Cell: ({ row }) => {
          const status = row?.original?.status
          let color
          switch (status) {
            case InfinityEnums.NotificationStatus.SENT:
              color = Colors.green
              break
            case InfinityEnums.NotificationStatus.SCHEDULED:
              color = Colors.gray
              break
            case InfinityEnums.NotificationStatus.CANCELLED:
              color = Colors.red
              break
            default:
              color = Colors.gray
          }

          return row?.index < notifications.length ? (
            <div
              className={Styles.Status}
              style={{
                backgroundColor: Colors.Status,
              }}
            >
              <IFText style={{ color: color }}>{status}</IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'notificationGroups',
        header:
          fetchNotificationsRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.NotificationsTableColumns.Groups')
          ),
        Cell: ({ row }) => {
          return row.index < notifications.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText>
                {row?.original?.notificationGroups &&
                row?.original?.notificationGroups.length > 0
                  ? row.original?.notificationGroups.length > 2
                    ? `${row.original.notificationGroups
                        .slice(0, 2)
                        .join(', ')} +${
                        row.original.notificationGroups.length - 2
                      }`
                    : row.original.notificationGroups.join(', ')
                  : '-'}
              </IFText>
            </div>
          ) : failedRequest ? (
            <></>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
      {
        accessorKey: 'scheduledAt',
        header:
          fetchNotificationsRequestState === RequestState.LOADING &&
          paginationOffset === 0 ? (
            <Skeleton
              variant="text"
              width="110px"
              animation="none"
              height={20}
            />
          ) : (
            t('PushNotificationsPanel.NotificationsTableColumns.ScheduledAt')
          ),
        Cell: ({ row }) => {
          return row.index < notifications.length ? (
            <div className={Styles.ColumnTitle}>
              <IFText> {formatDate(row?.original?.scheduledAt)}</IFText>
            </div>
          ) : failedRequest ? (
            <div className={Styles.IconDiv}>
              <IFButton
                size="sm"
                color={Colors.red}
                text={t('IFTable.TryAgain')}
                onClick={() => {
                  handleRetry()
                }}
              />
            </div>
          ) : (
            <Skeleton variant="text" width="60%" animation="none" height={20} />
          )
        },
      },
    ],

    [
      notifications,
      fetchNotificationsRequestState,
      failedRequest,
      notificationsFilter,
    ],
  )

  const fetchMoreOnBottomReached = useCallback(
    (event) => {
      if (event) {
        const { scrollHeight, scrollTop, clientHeight } = event
        if (scrollHeight - scrollTop - clientHeight < 400) {
          loadMoreData()
        }
      }
    },
    [loadMoreData],
  )

  const renderDetailPanel = ({ row }) => {
    return (
      <NotificationDetails
        row={row}
        editingAdminRole={editingAdminRole}
        fetchNotifications={() =>
          fetchNotifications(notificationsFilter, 0, false)
        }
        onEdit={(e) => {
          e.stopPropagation()
          notificationModalRef.current.show(row)
        }}
      />
    )
  }

  return (
    <div className={Styles.tables}>
      {((fetchNotificationsRequestState !== RequestState.SUCCEEDED &&
        !paginationOffset) ||
        notifications) && (
        <IFTable
          columns={notifcationsTableColumns}
          data={notifications}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
          paginationOffset={paginationOffset}
          filters={notificationsFilter}
          showSkeleton={
            (fetchNotificationsRequestState === RequestState.LOADING &&
              paginationOffset === 0) ||
            (fetchNotificationsRequestState !== RequestState.SUCCEEDED &&
              !paginationOffset)
          }
          renderDetailPanel={renderDetailPanel}
          tableMaxHeight={tablesHeight}
          tableSetHeight={'100%'}
          tableRequestState={fetchNotificationsRequestState}
        />
      )}
      <NotificationModal
        ref={notificationModalRef}
        title={t('PushNotificationsPanel.PanelTitleEdit')}
        isEdit={true}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotifications: (filter, offset, shouldShowError) =>
      dispatch(
        NotificationActions.fetchNotifications(filter, offset, shouldShowError),
      ),
  }
}

const mapStateToProps = (state) => ({
  notifications: NotificationSelectors.getNotifications(state),
  paginationOffset:
    NotificationSelectors.getNotificationsPaginationOffset(state),
  fetchNotificationsRequestState:
    NotificationSelectors.getFetchNotificationsRequestState(state),
  notificationsFilter: NotificationSelectors.getNotificationsFilter(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  editNotificationRequestState:
    NotificationSelectors.getEditNotificationRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList)
