const en = {
  UserList: {
    empty: 'No users found',
  },

  FundTransactionListItem: {
    refunded: 'Refunded',
    notRefunded: 'Refund',
    AdminTransaction: 'Admin Transaction',
  },

  ChargingTokenList: {
    empty: 'No tokens found',
    AppToken: 'App Token',
    Active: 'Active',
    type: 'Type',
    id: 'Token Uid',
    visualNumber: 'Visual Number',
    deactivateButton: 'Action',
    updatedAt: 'Linked On',
  },
  PaymentCardList: {
    title: 'Payment Cards',
    type: 'Type',
    cardNumber: 'Card Number',
    uid: 'Card Uid',
    createdAt: 'Linked On',
    country: 'Country',
    deleteButtonAction: 'Action',
    deleteButton: 'Delete',
    DeleteTitleModal: 'Delete Payment Card?',
    DeleteBodyText: 'This cannot be reversed.',
  },
  UserListHeader: {
    sort: 'Recent',
    users: 'Users',
  },
  ChargingTransactionListItem: {
    duration: 'Charging duration: ',
    uid: 'Charger uid: ',
    confirmRefund: 'This cannot be reversed.',
    confirmRefundTitle: 'Refund transaction?',
    refundButton: 'Refund',
  },
  IFRangePicker: {
    Min: 'Min',
    Max: 'Max',
    Ok: 'OK',
  },
  IFFilter: {
    saveButton: 'Apply',
    clearButton: 'Clear',
    activeOption: 'Active',
    inactiveOption: 'Inactive',
    dateRangeButton: 'Date',
    filterBy: 'Filter by',
    statusSelect: 'Status',
    or: ' or ',
    selectRange: 'Select Date Range',
    Ascending: 'Ascending',
    Descending: 'Descending',
    Download: 'Download',
  },

  AdminList: {
    empty: 'No admins found',
    deleteModalCancel: 'Cancel',
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    deleteModalText: 'Are you sure you want to delete this Administrator?',
    Name: 'Name',
    Email: 'Email',
    CreatedOn: 'Created On',
    Role: 'Role',
    Save: 'Save',
    Edit: 'Edit',
    Country: 'Country',
    AdminDeleted: 'Admin Deleted successfully',
  },
  InvitationsList: {
    InvitationSent: 'Invitation Sent',
    deleteInviteAdminModalText: 'Are you sure you want to delete this invite?',
    ResendInviteAdminEmail: 'Resend',
    deleteModalConfirm: 'Delete',
    Country: 'Country',
    InvitationDeleted: 'Invitation Deleted successfully',
  },

  RoleList: {
    Name: 'Name',
    Admins: 'Admins',
    Permissions: 'Permissions',
    UpdatedOn: 'Updated On',
    MoreInfo: 'More Info',
    EmptyAdmins: '--',
    deleteModalCancel: 'Cancel',
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    deleteModalText: 'Are you sure you want to delete this Role?',
    Country: 'Country',
    RoleSuccessCreated: 'Role Created Successfully',
    RoleDeletedSuccessfully: 'Role Deleted Successfully',
  },
  RoleDetails: {
    Admins: 'Admins',
    NoAdmins: 'No Admins Assigned.',
    Permissions: 'Permissions',
    Name: 'Name',
    CreatedAt: 'Created At',
    UpdatedAt: 'Updated At',
    Save: 'Save',
    Cancel: 'Cancel',
    Edit: 'Edit',
    RoleDetails: 'Role Details',
    RoleNameForm: 'Role Name',
    Country: 'Country',
    Submit: 'Submit',
    NoAdminsAssigned: 'No Admins Assigned to this Role',
  },

  DialogModal: {
    defaultCancelButtonText: 'Cancel',
  },

  LoginPage: {
    WelcomeTitle: 'Welcome Back!',
    WelcomeText: 'Sign in to your account',
    ForgotPassword: 'Forgot Password?',
    Login: 'Login',
    Required: 'Required*',
    ShortPassword: 'Password must be at least 8 characters',
  },
  ResetPassword: {
    Title: 'Reset Password',
    Email: 'Email',
    Name: 'Name',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    Required: 'Required*',
    ShortPassword: 'Password must be at least 8 characters',
    PasswordsMustMatch: 'Passwords do not match',
    Reset: 'Reset',
    ModalTitle: 'Confirm Password Reset',
    ModalSubTitle: 'Please enter the verification code sent to',
    PinValidationString: '0123456789',
    ModalButtonText: 'Confirm',
    ResetPasswordSuccess: 'Password was reset successfully',
    Required: 'Required*',
  },
  CreateAdminAccountPage: {
    CreateAccount: 'Create Account to continue.',
    Register: 'Register',
    Required: 'Required*',
    WelcomeTitle: 'Welcome!',
    ShortPassword: 'Password must be at least 8 characters',
    PasswordsMustMatch: 'Passwords do not match',
    Name: 'Name',
    Password: 'Password',
    ConfirmPassword: 'Confirm Password',
    Email: 'Email',
    AcceptedInvite: 'You have already accepted an invitation.',
    ToLogin: 'To login, please click ',
    Here: 'here.',
    InvitationSent: 'Invitation sent successfully',
    AccountCreated: 'Account created successfully',
  },

  AdminPage: {
    AdminFilterPlaceholder: 'Filter admins by...',
    InviteAdminButton: 'Invite',
    NewRole: 'New Role',
    CreateNewRole: 'Create New Role',
    InviteAdmin: 'Invite Admin',
    InviteAdminEmail: 'Email',
    InviteAdminRole: 'Role',
    InviteAdminCountry: 'Country',
    FieldRequired: 'Required*',
    InvalidEmail: 'Invalid Email',
    MinThreeCharacters: 'Name must be at least 3 characters',
  },

  UserPage: {
    LastUpdated: 'Last Updated',
    Email: 'Email',
    Phone: 'Phone',
    Country: 'Country',
    JoinedOn: 'Joined On',
    Wallet: 'Wallet',
    ChargingTokensTitle: 'Charging Tokens',
    ChargingTokensButton: 'Link',
    TransactionsTitle: 'Transactions',
    TransactionNoRecord: 'No records to display',
    EmptyUsers: 'No records to display',
    filterNoUsers: 'Filter does not match any logs, try a new search',
    query: 'query',
    TransactionTableColumns: {
      type: 'Type',
      Type: {
        charge: 'Charge',
        addMoney: 'Add Money',
        refund: 'Refund',
      },
      transactionID: 'Transaction ID',
      Name: 'Reference',
      Status: 'Status',
      Amount: 'Amount',
      ProcessedOn: 'Processed on',
    },
    paymentTableColumns: {
      paymentID: 'Payment ID',
      Status: 'Status',
      Amount: 'Amount',
      ProcessedOn: 'Processed on',
    },
    ActionsTitle: 'Actions',
    AdjustTitle: 'Adjust Wallet',
    AddCardTitle: 'Link cards',
    AddCardText:
      'Please enter the visual card numbers, separated by commas or line breaks.',
    AddCardErrorMessage: 'There is a an issue with the following cards:',
    EditButton: 'Edit',
    DeactivateButton: 'Deactivate',
    ActivateButton: 'Activate',
    AddButton: 'Add',
    SubtractButton: 'Subtract',
    Amount: 'Amount',
    Currency: 'EGP',
    Points: 'PTS',
    DeactivateTitle: 'Deactivate User?',
    ActivateTitle: 'Activate User?',
    DeactivateTitleToken: 'Deactivate Token?',
    DeactivateBodyText: 'This user will no longer be able to use the App.',
    ActivateBodyText: 'Are you sure you want to activate this user?',
    DeactivateConfirm: 'Deactivate',
    ActivateConfirm: 'Activate',
    AddCardTextFieldLabel: 'Visual Numbers',
    AddCardButtonText: 'Add',
    AddCardSuccessMessage: 'Card(s) linked Successfully',
    ChangePhoneSuccessMessage: 'Phone Number Changed Successfully',
    AdjustAmountButtonTextAdd: 'Add',
    AdjustAmountButtonTextSubtract: 'Subtract',
    UsersFilterPlaceholder: 'Filter users by',
    TransactionsFilterPlaceholder: 'Filter transactions by',
    PaymentsFilterPlaceholder: 'Filter payment sessions by',
    EditPhoneNumberModalTitle: 'Edit Phone Number',
    EditPhoneNumberModalAcceptText: 'Done',
    EditPhoneNumberTextFieldTitle: 'Phone',
    DeactivateTokenText: 'This cannot be reversed.',
    Deactivated: 'Deactivated',
    PointsInfoModalTitle: 'Points',
    NoSessions: 'There are no sessions',
    WalletTitle: 'Wallet',
    WalletSuccessMessage: 'Wallet Adjusted Successfully',
    //Adjust Wallet Items
    AmountTitle: 'Current Amount',
    ActionTitle: 'Action',
    Amount: 'Amount',
    AdjustedAmount: 'Adjusted Amount',
    Adjust: 'Adjust',
  },
  TransactionDetails: {
    CharginSessionTitle: 'Charging Session Details',
    Name: 'Station Name',
    Start: 'Start',
    End: 'End',
    Duration: 'Duration',
    Consumption: 'Consumption',
    Tariff: 'Tariff',
    PointsTariff: 'Points Tariff',
    ChargerType: 'Charger Type',
    ChargerID: 'Charger ID',
    TransactionDetailsTitle: 'Transaction Details',
    TokenType: 'Token Type',
    TokenID: 'Token ID',
    TransactionType: 'Transaction Type',
    Amount: 'Amount',
    PointsAmount: 'Points Amount',
  },
  FundTransactionDetails: {
    General: {
      title: 'Payment Details',
      amount: 'Amount',
      credit: 'is Credit',
      refunded: 'is Refunded',
      status: 'status',
      issuer: 'Payment Issuer',
    },
    title: 'Payment Issuer Details',
    Qnb: {
      fees: 'Fees',
      orderId: 'Order ID',
      paymentId: 'Payment ID',
    },
    Internal: {
      adminId: 'Admin ID',
    },
  },
  PointsItem: {
    ExpiredText: 'Expired',
    ExpiresOnText: 'Expires on ',
    CreatedOnText: 'Created on ',
    pointsTableColumns: {
      Name: 'Name',
      Status: 'Status',
      Balance: 'Balance',
      CreatedOn: 'Created on',
    },
  },

  PointsList: {
    NoPointsText: 'No Points',
    NoSessions: 'There are no sessions',
    PaymentSessions: 'Payment Sessions',
  },

  PushNotificationsPanel: {
    PanelTitleSend: 'Send Push Notification',
    PanelTitleEdit: 'Edit Push Notification',
    PanelTitleGroupEdit: 'Edit Notification Group',
    PanelTitleGroupCreate: 'Create Notification Group',
    EnglishTitle: 'Title (en)',
    EnglishBody: 'Body (en)',
    ArabicTitle: 'Title (ar)',
    ArabicBody: 'Body (ar)',
    Group: 'Group',
    ScheduledAt: 'Scheduled At',
    StationId: 'Station ID / URl',
    DevicePreviewTitle: 'Device View',
    Required: 'Required*',
    MinTitle: 'Title must be at least 3 characters',
    MinBody: 'Body must be at least 3 characters',
    sendButton: 'Send',
    editButton: 'Submit',
    saveButton: 'Save',
    deleteButton: 'Delete',
    createButton: 'Create',
    invalidIdText: 'Please enter a valid ID',
    sendNow: 'Now',
    sendLater: 'Later',
    minName: 'Name must be at least 3 characters',
    scheduleButton: 'Schedule',
    scheduleSuccess: 'Notification Scheduled Successfully',
    submitButton: 'Submit',
    editButton: 'Edit',
    cancelButton: 'Cancel',
    SuccessMessage: 'Notification Sent Successfully',
    editSuccess: 'Notification Edited Successfully',
    editGroupSuccess: 'Group Edited Successfully',
    deleteGroupSuccess: 'Group Deleted Successfully',
    createGroupSuccess: 'Group Created Successfully',
    scheduleSuccess: 'Notification Scheduled Successfully',
    cancelSuccess: 'Notification Cancelled Successfully',
    NotiFilterPlaceholder: 'Filter Notifications by',
    GroupFilterPlaceholder: 'Filter Notification Groups by',
    NotificationsTableColumns: {
      Title: 'Notification Title',
      Id: 'Notification ID',
      Status: 'Transaction Status',
      Groups: 'Notification Groups',
      ScheduledAt: 'Scheduled at',
    },
    confirmCancel: ' Confirm',
    cancelText: 'Are you sure you want to cancel this notification?',
    deleteGroupText: 'Are you sure you want to delete this notification group?',
    cancelSuccess: 'Notification Cancelled Successfully',

    dialogTitle: 'Confirm Cancel',
    groupDialogTitle: 'Delete Notification Group',
    confirmCancel: ' Confirm',
    GroupsTableColumns: {
      Name: 'Name',
      Count: 'Users Count',
      Country: 'Country',
      Query: 'Query',
    },
    notificationsTab: 'Notifications',
    groupsTab: 'Groups',
  },
  NotificationDetails: {
    Title: 'Notification Details',
    EnglishTitle: 'Title (en)',
    ArabicTitle: 'Title (ar)',
    EnglishMessage: 'Body (en)',
    ArabicBody: 'Body (ar)',
    Url: 'URL',
    Groups: 'Notification Groups',
    CreatedAt: 'Created At',
    ScheduledAt: 'Scheduled At',
  },

  TransactionsPage: {
    filterNoTransaction: 'Filter does not match any logs, try a new search',
    TransactionsFilterPlaceholder: 'Filter transactions by',
    TotalMoneyCollected: 'Total Money Collected',
    TotalChargingBalance: 'Total Charging Balance',
    TotalEnergyConsumption: 'Total Energy Consumption',
    TotalUsers: 'Number of Active Users',
    PhoneNumber: 'Phone Number',
    CustomerName: 'Name',
    TransactionId: 'Transaction ID',
    TransactionType: 'Transaction Type',
    Amount: 'Amount',
    EnergyConsumption: 'Energy Consumption',
    TransactionDate: 'Transaction Date',
    NoTransactions: 'No transactions found',
    NoTransactionsList: 'There are no transactions',
    ChartNoData: 'No Data Available',
    ChartYAxis: 'Energy Consumption (kWh)',
    TransactionTableColumns: {
      UserId: 'User ID',
      TransactionId: 'Transaction ID',
      TransactionType: 'Transaction Type',
      Amount: 'Amount',
      EnergyConsumption: 'Energy Consumption',
      TransactionDate: 'Transaction Date',
    },
  },

  VouchersPage: {
    NoVouchers: 'No vouchers found',
    VouchersFilterPlaceholder: 'Filter vouchers by',
    VouchersTitle: 'Vouchers',
    VouchersName: 'Name',
    VouchersCode: 'Code',
    VouchersPoints: 'Points',
    VouchersRedeemed: 'Redeemed',
    VouchersValidity: 'Validity (days)',
    VouchersExpiry: 'Expiry',
    Country: 'Country',
    ExpiredText: 'Expired',
    ExpiresOnText: 'Expires on ',
    Add: 'Add',
    AddNewVoucher: 'Add Voucher',
    Required: 'Required*',
    Submit: 'Submit',
    VoucherNameForm: 'Voucher name',
    VoucherCodeForm: 'Voucher code',
    VoucherPointsForm: 'Number of points',
    VoucherMaxRedeemCountPerUser: 'Number of redeems per user',
    RedeemPerUser: 'Redeem Per User',
    VoucherMaxRedeemedForm: 'Number of redeems',
    ValidityForm: 'Validity (days)',
    ExpiryForm: 'Expiry date',
    Done: 'Done',
    Cancel: 'Cancel',
    NoExpiryDate: 'No Expiry Date',
    AddVoucherSuccessMessage: 'Voucher added Successfully',
    deleteVoucherSuccessMessage: 'Voucher deleted Successfully',
    PositiveNumber: 'Value must be greater than 0',
    MinChars: 'Name must be at least 3 characters',
    MaxChars: 'Name must not exceed 50 characters',
    DeleteVoucher: 'Delete Voucher?',
    DeleteVoucherText: 'This cannot be reversed.',
    DeleteConfirm: 'Delete',
    Save: 'Save',
    Edit: 'Edit',
    Delete: 'Delete',
  },

  ReverseTransactionModal: {
    EnergyConsumptionText: 'Energy Consumption (kWh)',
    ChargingLevelText: 'Charging Level',
    SubmitText: 'Submit',
    Title: 'Reverse Charging Transaction',
    ReverseButtonText: 'Reverse',
    ReversedButtonText: 'Reversed',
    SuccessMessage:
      'Transaction reversed successfully. It may take a while for the corrected transaction to appear.',
    ReverseTransactionType: 'Reverse Type',
    ConfirmDialogTitleReverse: 'Reverse Transaction',
    ConfirmDialogTitleCorrect: 'Correct Transaction',
    ConfirmDialogSubtitleReverse:
      'Are you sure you want to reverse this transaction?',
    ConfirmDialogSubtitleCorrect:
      'Are you sure you want to correct this transaction?',
    ConfirmDialogButton: 'Confirm',
  },

  PaymentSession: {
    Reason: 'Reason:',
  },

  IFTable: {
    TryAgain: 'Retry',
    TryAgainText: "Couldn't load data",
  },

  SideNavBar: {
    AdminProfile: 'My Profile',
  },
  AdminProfile: {
    logout: 'Logout',
    Email: 'Email',
    Role: 'Role',
    JoinedOn: 'Joined On',
    Countries: 'Registered Countries',
    Password: 'Password',
    ChangePassword: 'Change Password',
    ConfirmChangePassword: 'Confirm',
    CurrentPassword: 'Current Password',
    NewPassword: 'New Password',
    ConfirmNewPassword: 'Confirm New Password',
    Required: 'Required*',
    MinLength: 'Password must be at least 8 characters',
    PasswordsMustMatch: 'Passwords do not match',
    ChangePasswordSuccessMessage: 'Password changed Successfully',
    EditUserNameTitle: 'Edit User Name',
    EditUserNameLabel: 'User Name',
    EditUserNameConfirm: 'Confirm',
    ChangeUserNameSuccessMessage: 'User Name changed Successfully',
    AdminActions: 'Actions',
    AdminActionsFilterPlaceHolder: 'Filter actions by',
  },
  AdminActions: {
    EditingAdmin: 'You',
    AdminPronoun: 'your',
    from: 'from',
    Changed: 'changed',
    Removed: 'removed',
    Created: 'created',
    Deleted: 'deleted',
    Activated: 'activated',
    Deactivated: 'deactivated',
    Invited: 'invited',
  },
  Dashboard: {
    ListEnergyConsumption: 'Energy Consumption(kWh)',
    ListRedeemCount: 'Redeem Count',
    ActiveUsers: 'Active Users',
    TotalSales: 'Total Sales',
    TotalChargingTransactions: 'Total Charging Transactions',
    TotalAddedMoney: 'Total Added Money',
    TotalChargingMoney: 'Total Charging Money',
    TotalUsers: 'Total Users',
    Wallets: 'Wallets',
    TopUsers: 'Top Users',
    TopVouchers: 'Top Vouchers',
    NegativeWallets: 'Negative Wallets',
    PositiveWallets: 'Positive Wallets',
    InactiveUsers: 'Inactive Users',
    NewUsers: 'New Users',
    ReturningUsers: 'Returning Users',
    DashboardFilterPlaceholder: 'Filter Dashboard by',
    ThisYear: 'This Year',
    PreviousYear: 'Previous Year',
  },
  CountriesPage: {
    Add: 'Add',
    CountriesFilterPlaceholder: 'Filter countries by',
    CurrenciesFilterPlaceholder: 'Filter currencies by',
    CountryName: 'Name',
    CountryCode: 'Code',
    Currency: 'Currency',
    Bounds: 'Bounds',
    SW: 'SW:',
    NE: 'NE:',
    lat: 'lat',
    lon: 'lon',
    Cancel: 'Cancel',
    Save: 'Save',
    Edit: 'Edit',
    AddNewCountry: 'Add Country',
    Required: 'Required*',
    CountryNameForm: 'Country name',
    CountryCodeForm: 'Country code',
    CountryCurrencyForm: 'Currency name',
    CountryBoundsForm: 'Country Bounds',
    Submit: 'Submit',
    AddCountrySuccessMessage: 'Country added Successfully',
    AddCurrencySuccessMessage: 'Currency added Successfully',
    Symbol: 'Symbol',
    DecimalPlaces: 'Decimal Places',
    AddNewCurrency: 'Add Currency',
    CurrencyCodeForm: 'Currency Code',
    CurrencySymbolForm: 'Currency Symbol',
    CurrencyDecimalForm: 'Currency Decimal Places',
  },
  Tariffs: {
    Time: 'Time',
    MaxPower: 'Max Power',
    Subscriptions: 'Subscriptions',
    CurrentType: 'Current Type',
    MoneyTariff: 'EGP/kWh',
    PointsTariff: 'PTS/kWh',
    EnergyConsumed: 'Energy Consumed (kWh)',
    NoTariffs: 'No tariffs found',
  },
  AppChargingTokenPage: {
    Uid: 'Uid',
    VisualNumber: 'Visual Number',
    Reference: 'Reference',
    Type: 'Type',
    Design: 'Design',
    CreatedOn: 'Created On',
    Status: 'Status',
    Details: 'Details',
    ParentID: 'Parent ID',
    VoucherCode: 'Voucher',
    AppChargingTokenFilterPlaceholder: 'Filter Tokens by...',
    SelectDesign: 'Select Design',
    NotSet: '--',
    AppChargingTokenDeleted: 'App Charging Token Deleted successfully',
    deleteModalTextToken:
      'Are you sure you want to delete this App Charging Token?',
    deleteModalCancel: 'Cancel',
    deleteModalConfirm: 'Delete',
    deleteModalTitle: 'Confirm Delete',
    AddAppChargingTokenDesign: 'New Design',
    DesignName: 'Name',
    Required: 'Required*',
    Save: 'Save',
    Edit: 'Edit',
    Or: 'or',
    DragAndDrop: 'Drag and drop a file here or click',
    AddNewCard: 'Add New Card',
    Submit: 'Submit',
    AppChargingTokenCreated: 'App Charging Token Created Successfully',
    AppChargingTokenBulkCreated: 'App Charging Tokens Created Successfully',
    CreateAppChargingToken: 'New Token',
    DownloadSampleCSV: 'Download Sample CSV',
    AppChargingTokenDesignAdded: 'App Charging Token Design added successfully',
    Invalid: 'Invalid',
    CreateDesign: 'New Design',
    NoDesignsFound: 'No Designs Found',
    EditDesign: 'Edit Design',
    deleteModalText: 'Are you sure you want to delete this Design?',
    MinThreeCharacters: 'This field must be at least 3 characters',
  },
}

export default en
