import React, { useState, useEffect, useRef } from 'react'
import NotificationPageTabs from 'Containers/NotificationPageTabs/NotificationPageTabs'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import Styles from './NotificationsPage.module.css'
import Colors from 'Theme/Colors'
import InfinityEnums from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import { IFButton, IFFilter, IFModal, IFSkeleton } from 'Components'
import {
  NotificationModal,
  NotificationsList,
  NotificationGroupsList,
  CreateNotificationGroupModal,
} from 'Containers'
import { useTranslation } from 'react-i18next'
import { Scrollbars } from 'react-custom-scrollbars'

const NotificationsPage = ({
  notificationsCount,
  groupsCount,
  fetchNotificationsCountRequestState,
  fetchGroupsCountRequestState,
  fetchNotificationsCount,
  fetchGroupsCount,
  clearNotifications,
  notificationsFilter,
  fetchNotificationsRequestState,
  fetchNotifications,
  groupsFilter,
  editingAdminRole,
  admin,
  setCountryFilterNoti,
  notificationGroups,
  fetchNotificationGroups,
  setCheckFilter,
  setNotificationsFilter,
  scheduleNotificationRequestState,
  fetchGroupsRequestState,
  createGroupRequestState,
  editGroupRequestState,
  clearGroups,
  setGroupsFilter,
  fetchGroups,
}) => {
  const { t } = useTranslation()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const [firstLoad, setFirstLoad] = useState(true)
  const [tablesHeight, setTablesHeight] = useState(0)
  const notificationModalRef = useRef()
  const groupModalRef = useRef()
  const notificationsTableRef = useRef(null)
  const groupsTableRef = useRef(null)
  const notiFilterRef = useRef(null)
  const groupsFilterRef = useRef(null)

  const canEditNotification = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATIONS,
  )
  const canViewGroups = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATION_GROUPS,
  )
  const canViewNotifications = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_VIEW_NOTIFICATIONS,
  )
  const canEditGroups = editingAdminRole?.includes(
    InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATION_GROUPS,
  )
  useEffect(() => {
    if (!canViewNotifications && canViewGroups) setSelectedTabIndex(1)
  }, [])
  useEffect(() => {
    if (canViewNotifications) fetchNotificationGroups()
  }, [canViewNotifications])
  useEffect(() => {
    if (scheduleNotificationRequestState === RequestState.SUCCEEDED) {
      notificationModalRef.current.dismiss()
      fetchNotifications(notificationsFilter, 0)
      fetchNotificationsCount(notificationsFilter, 0)
    }
  }, [scheduleNotificationRequestState])

  useEffect(() => {
    if (createGroupRequestState === RequestState.SUCCEEDED) {
      groupModalRef.current.dismiss()
      fetchGroups(groupsFilter, 0)
      fetchGroupsCount(groupsFilter, 0)
    }
  }, [createGroupRequestState, editGroupRequestState])
  useEffect(() => {
    if (editGroupRequestState === RequestState.SUCCEEDED) {
      groupModalRef.current.dismiss()
    }
  }, [editGroupRequestState])

  useEffect(() => {
    setCountryFilterNoti(admin)
    const names = notificationGroups.map((item) => item.name)
    setCheckFilter(names)
    setFirstLoad(true)

    return () => {}
  }, [notificationGroups])

  const handleTabChange = (newValue) => {
    setSelectedTabIndex(newValue)
  }

  useEffect(() => {
    if (canViewNotifications) fetchNotificationsCount(notificationsFilter)
    if (canViewGroups) fetchGroupsCount(groupsFilter)
  }, [])
  useEffect(() => {
    if (
      (fetchNotificationsRequestState === RequestState.SUCCEEDED &&
        fetchNotificationsCountRequestState === RequestState.SUCCEEDED &&
        canViewNotifications &&
        firstLoad) ||
      (fetchGroupsCountRequestState === RequestState.SUCCEEDED &&
        fetchGroupsRequestState === RequestState.SUCCEEDED &&
        canViewGroups &&
        firstLoad)
    ) {
      setFirstLoad(false)
    }
  }, [
    fetchNotificationsRequestState,
    fetchNotificationsCountRequestState,
    fetchGroupsCountRequestState,
    fetchGroupsRequestState,
    firstLoad,
    canViewNotifications,
    canViewGroups,
  ])
  useEffect(() => {
    if (canViewNotifications) {
      fetchNotifications(notificationsFilter, 0)
    }
    if (canViewGroups) {
      fetchGroups(groupsFilter, 0)
    }
  }, [canViewNotifications, canViewGroups])

  useEffect(() => {
    const handleResize = (entries) => {
      if (entries[0]) {
        const { height } = entries[0].contentRect
        setTablesHeight(height)
      }
    }
    const resizeObserver = new ResizeObserver(handleResize)
    if (notificationsTableRef.current) {
      resizeObserver.observe(notificationsTableRef.current)
    }
    if (groupsTableRef.current) {
      resizeObserver.observe(groupsTableRef.current)
    }
    return () => {
      if (notificationsTableRef.current) {
        resizeObserver.unobserve(notificationsTableRef.current)
      }
      if (groupsTableRef.current) {
        resizeObserver.unobserve(groupsTableRef.current)
      }
    }
  })
  useEffect(() => {
    if (
      firstLoad &&
      fetchNotificationsRequestState === RequestState.LOADING &&
      canViewNotifications &&
      progress === 0
    ) {
      setProgress(progress + 10)
    }

    if (
      firstLoad &&
      canViewNotifications &&
      fetchNotificationsRequestState === RequestState.SUCCEEDED
    ) {
      setProgress(100)
    }
    if (
      firstLoad &&
      canViewNotifications &&
      fetchNotificationsRequestState !== RequestState.LOADING &&
      fetchNotificationsRequestState !== RequestState.UNINITIALIZED &&
      fetchNotificationsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchNotificationsRequestState, canViewNotifications])

  const handleSendButton = () => {
    notificationModalRef.current.show()
  }
  const handleCreate = () => {
    groupModalRef.current.show()
    groupModalRef.current.resetValues()
  }
  const handleFilterChange = (newFilter) => {
    if (canViewNotifications) {
      clearNotifications()
      setNotificationsFilter(newFilter)
      fetchNotifications(newFilter, 0)
      fetchNotificationsCount(newFilter)
    }
  }

  const handleFilterChangeGroup = (newFilter) => {
    clearGroups()
    setGroupsFilter(newFilter)
    fetchGroups(newFilter, 0)
    fetchGroupsCount(newFilter)
  }
  return (
    <div className={Styles.Wrapper}>
      <div
        className={Styles.NotificationsContainer}
        style={{
          backgroundColor: Colors.white,
          borderColor: Colors.UserPageBackgroundContainer,
        }}
      >
        <div className={Styles.TabButtonDiv}>
          <NotificationPageTabs
            onValueChange={handleTabChange}
            initialIndex={selectedTabIndex}
            isLoading={
              fetchNotificationsRequestState &&
              (fetchNotificationsRequestState === RequestState.LOADING ||
                fetchNotificationsCountRequestState === RequestState.LOADING)
            }
            notificationsFilter={notificationsFilter}
            notificationsCount={notificationsCount}
            groupsCount={groupsCount}
            canViewGroups={canViewGroups}
            canViewNotifications={canViewNotifications}
          />

          {(canEditGroups || canEditNotification) && (
            <div>
              {(firstLoad &&
                fetchGroupsRequestState === RequestState.LOADING) ||
              (firstLoad &&
                fetchNotificationsRequestState === RequestState.LOADING) ? (
                <IFSkeleton variant="text" width="100px" />
              ) : (
                <>
                  {selectedTabIndex === 0
                    ? canEditNotification && (
                        <IFButton
                          size="sm"
                          color={Colors.primary}
                          isFill={false}
                          isLoading={false}
                          text={t('PushNotificationsPanel.sendButton')}
                          className={Styles.SendNotificationButton}
                          onClick={handleSendButton}
                        />
                      )
                    : canEditGroups && (
                        <IFButton
                          size="sm"
                          color={Colors.primary}
                          isFill={false}
                          isLoading={false}
                          text={t('PushNotificationsPanel.createButton')}
                          className={Styles.SendNotificationButton}
                          onClick={handleCreate}
                        />
                      )}
                </>
              )}
            </div>
          )}
        </div>
        {(canViewNotifications || canViewGroups) &&
          (selectedTabIndex === 0
            ? canViewNotifications && (
                <div className={Styles.AdminFilterDiv}>
                  <IFFilter
                    ref={notiFilterRef}
                    onFilterChange={(newFilter) =>
                      handleFilterChange(newFilter)
                    }
                    filters={notificationsFilter}
                    textFieldPlaceholder={t(
                      'PushNotificationsPanel.NotiFilterPlaceholder',
                    )}
                    isLoading={
                      fetchNotificationsRequestState === RequestState.LOADING
                    }
                  />
                </div>
              )
            : canViewGroups && (
                <div className={Styles.AdminFilterDiv}>
                  <IFFilter
                    ref={groupsFilterRef}
                    onFilterChange={(newFilter) =>
                      handleFilterChangeGroup(newFilter)
                    }
                    filters={groupsFilter}
                    textFieldPlaceholder={t(
                      'PushNotificationsPanel.GroupFilterPlaceholder',
                    )}
                    isLoading={fetchGroupsRequestState === RequestState.LOADING}
                  />
                </div>
              ))}

        <Scrollbars className={Styles.ScrollParent}>
          {selectedTabIndex === 0 && canViewNotifications && (
            <div className={Styles.Tables} ref={notificationsTableRef}>
              <NotificationsList tablesHeight={tablesHeight} />
            </div>
          )}
          {selectedTabIndex === 1 && canViewGroups && (
            <div className={Styles.Tables} ref={notificationsTableRef}>
              <NotificationGroupsList tablesHeight={tablesHeight} />
            </div>
          )}
        </Scrollbars>
      </div>
      <NotificationModal
        ref={notificationModalRef}
        title={t('PushNotificationsPanel.PanelTitleSend')}
        isEdit={false}
      />
      <CreateNotificationGroupModal
        ref={groupModalRef}
        title={t('PushNotificationsPanel.PanelTitleGroupCreate')}
        isEdit={false}
        admin={admin}
        adminRole={editingAdminRole}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchNotificationsCount: (filter) =>
      dispatch(NotificationActions.fetchNotificationsCount(filter)),
    fetchGroupsCount: (filter) =>
      dispatch(NotificationActions.fetchGroupsCount(filter)),
    fetchNotifications: (filter, offset, shouldShowError) =>
      dispatch(
        NotificationActions.fetchNotifications(filter, offset, shouldShowError),
      ),
    setCountryFilterNoti: (admin) =>
      dispatch(NotificationActions.setCountryFilterNoti(admin)),
    clearNotifications: () =>
      dispatch(NotificationActions.clearNotifications()),
    fetchNotificationGroups: () =>
      dispatch(NotificationActions.fetchNotificationGroups()),
    setCheckFilter: (names) =>
      dispatch(NotificationActions.setCheckFilter(names)),
    setNotificationsFilter: (newFilter) =>
      dispatch(NotificationActions.setNotificationsFilter(newFilter)),
    clearGroups: () => dispatch(NotificationActions.clearGroups()),
    setGroupsFilter: (newFilter) =>
      dispatch(NotificationActions.setGroupsFilter(newFilter)),
    fetchGroups: (filter, offset, shouldShowError) =>
      dispatch(
        NotificationActions.fetchGroups(filter, offset, shouldShowError),
      ),
  }
}

const mapStateToProps = (state) => ({
  notificationsCount: NotificationSelectors.getNotificationsCount(state),
  groupsCount: NotificationSelectors.getGroupsCount(state),
  fetchNotificationsCountRequestState:
    NotificationSelectors.getNotificationsCountRequestState(state),
  fetchGroupsCountRequestState:
    NotificationSelectors.getGroupsCountRequestState(state),
  notificationsFilter: NotificationSelectors.getNotificationsFilter(state),
  groupsFilter: NotificationSelectors.getGroupsFilter(state),
  fetchNotificationsRequestState:
    NotificationSelectors.getFetchNotificationsRequestState(state),
  editingAdminRole: AuthSelectors.getEditingAdminRole(state),
  admin: AuthSelectors.getUser(state),
  notificationGroups: NotificationSelectors.getNotificationGroups(state),
  scheduleNotificationRequestState:
    NotificationSelectors.getScheduleNotificationRequestState(state),
  fetchGroupsRequestState:
    NotificationSelectors.getFetchGroupsRequestState(state),
  createGroupRequestState:
    NotificationSelectors.getCreateGroupRequestState(state),
  editGroupRequestState: NotificationSelectors.getEditGroupRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage)
