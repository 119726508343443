import React, { useState, useRef, useImperativeHandle, useEffect } from 'react'
import { TextField, FormGroup, InputAdornment } from '@mui/material'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import NotificationActions from 'Stores/Notification/Actions'
import NotificationSelectors from 'Stores/Notification/Selectors'
import ReactFlagsSelect from 'react-flags-select'
import Styles from './CreateNotificationGroupModal.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import {
  IFModal,
  IFText,
  IFButton,
  IFDialog,
  QueryBuilder,
  IFSkeleton,
  IFLoadingIndicator,
} from 'Components'
import { styled as muiStyled } from '@mui/system'
import PropTypes from 'prop-types'
import { MuiConfig } from '@react-awesome-query-builder/mui'
import '@react-awesome-query-builder/mui/css/styles.css'
const CssTextField = muiStyled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: Colors.text,
  },
  '& .MuiOutlinedInput-root': {
    height: '39px',
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary,
    },
    '&:hover fieldset': {
      borderColor: Colors.primary,
    },
  },
}))

function formatQuery(queryString) {
  const queryObj = JSON.parse(queryString)
  const formattedQuery = {}

  function processValue(value) {
    if (Array.isArray(value)) {
      return value.map(processValue)
    } else if (typeof value === 'object' && value !== null) {
      const processed = {}
      for (const [operator, operatorValue] of Object.entries(value)) {
        processed[operator] = processValue(operatorValue)
      }
      return processed
    } else if (
      typeof value === 'string' &&
      value.startsWith("'") &&
      value.endsWith("'")
    ) {
      return value.slice(1, -1)
    }
    return value
  }

  function processGroup(conditions) {
    return conditions.map((condition) => {
      if (typeof condition === 'object' && condition !== null) {
        return Object.fromEntries(
          Object.entries(condition).map(([field, fieldValue]) => {
            if (field === '$or' || field === '$and') {
              return [field, processGroup(fieldValue)]
            }
            return [field, processValue(fieldValue)]
          }),
        )
      }
      return condition
    })
  }

  for (const [key, value] of Object.entries(queryObj)) {
    if (Array.isArray(value) && (key === '$or' || key === '$and')) {
      formattedQuery[key] = processGroup(value)
    } else if (key === '$not' && typeof value === 'object' && value !== null) {
      formattedQuery[key] = Object.fromEntries(
        Object.entries(value).map(([subKey, subValue]) => [
          subKey,
          processValue(subValue),
        ]),
      )
    } else {
      formattedQuery[key] = processValue(value)
    }
  }

  return formattedQuery
}
const processDates = (obj) => {
  if (typeof obj !== 'object' || obj === null) return obj

  if (Array.isArray(obj)) {
    return obj.map(processDates)
  }
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === 'object') {
      if (key === '$or' || key === '$and') {
        obj[key] = obj[key].map(processDates)
      } else {
        obj[key] = processDates(obj[key])
      }
    }
    if (key === 'dateOfBirth' || key === 'createdAt') {
      obj[key] = fixLocalDate(obj[key])
    }
  }

  return obj
}
const fixLocalDate = (dateObj) => {
  if (typeof dateObj === 'object' && dateObj !== null) {
    const operator = Object.keys(dateObj)[0]

    if (['$gt', '$lt', '$gte', '$lte', '$ne'].includes(operator)) {
      const rawDate = dateObj[operator]
      if (!rawDate) return null

      const localDate = new Date(rawDate)
      if (isNaN(localDate.getTime())) {
        return null
      }

      localDate.setMinutes(
        localDate.getMinutes() - localDate.getTimezoneOffset(),
      )

      return { [operator]: localDate.toISOString().split('T')[0] }
    } else {
      const localDate = new Date(dateObj)
      if (isNaN(localDate.getTime())) {
        return null
      }

      localDate.setMinutes(
        localDate.getMinutes() - localDate.getTimezoneOffset(),
      )

      return localDate.toISOString().split('T')[0]
    }
  }
  if (typeof dateObj === 'string') {
    let localDate = new Date(dateObj)

    if (isNaN(localDate.getTime())) {
      localDate = new Date(Date.parse(dateObj))
    }

    if (isNaN(localDate.getTime())) {
      return null
    }

    localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset())

    return localDate.toISOString().split('T')[0]
  }

  return null
}
const CreateNotificationGroupModal = React.forwardRef(
  (
    {
      title,
      isEdit,
      admin,
      editGroup,
      createGroup,
      createGroupRequestState,
      editGroupRequestState,
      deleteGroup,
      deleteGroupRequestState,
      queryCount,
      fetchQueryCountRequestState,
      fetchQueryCount,
      invalidId,
      setInvalidId,
      adminRole,
    },
    ref,
  ) => {
    const modalRef = useRef()
    const { t } = useTranslation()
    const [name, setName] = useState('')
    const [nameError, setNameError] = useState('')
    const [country, setCountry] = useState('')
    const [canSubmit, setCanSubmit] = useState(false)
    const [countryInputFilled, setCountryInputFilled] = useState(false)
    const [selected, setSelected] = useState()
    const [prefix, setPrefix] = useState()
    const [query, setQuery] = useState('')
    const [queryError, setQueryError] = useState('')

    const canEditGroups = adminRole?.includes(
      InfinityEnums.AdminPermissions.CAN_EDIT_NOTIFICATION_GROUPS,
    )
    let config = {
      ...MuiConfig,
      fields: {
        _id: {
          label: 'ID',
          type: 'text',
          operators: ['equal', 'not_equal'],
        },
        email: {
          label: 'Email',
          type: 'text',
          operators: [
            'equal',
            'not_equal',
            'like',
            'not_like',
            'starts_with',
            'ends_with',
          ],
        },
        createdAt: {
          label: 'Created At',
          type: 'date',
          operators: ['equal', 'not_equal', 'greater', 'less'],
        },

        phone: {
          label: 'Phone',
          type: 'text',
          operators: [
            'equal',
            'not_equal',
            'like',
            'not_like',
            'starts_with',
            'ends_with',
          ],
        },
        name: {
          label: 'Name',
          type: 'text',
          operators: [
            'equal',
            'not_equal',
            'like',
            'not_like',
            'starts_with',
            'ends_with',
          ],
        },
        isActive: {
          label: 'Is Active',
          type: 'boolean',
          operators: ['equal', 'not_equal'],
        },
        dateOfBirth: {
          label: 'Date of Birth',
          type: 'date',
          operators: ['equal', 'not_equal', 'greater', 'less'],
        },
      },
      removeFieldComparisons: true,
      canNegate: true,
    }
    config.settings.valueSourcesInfo = {}
    config.canNegate = true
    const [data, setData] = useState({})

    const show = (send) => {
      if (isEdit) setData(send.original)
      else setQuery({})
      setInvalidId(false)
      setCanSubmit(false)
      modalRef.current.show()
    }

    useEffect(() => {
      if (isEdit && data && canEditGroups) {
        const groupName = data?.groupName || ''

        if (groupName.charAt(2) === '-') {
          setName(groupName.substring(3))
        } else {
          setName(groupName)
        }

        setCountry(data.groupCountry)
        setQuery(data.groupQuery ? formatQuery(data.groupQuery) : '')
        if (data.groupQuery && data.countryCode) {
          const payload = {
            query: data?.groupQuery,
            country: data?.countryCode,
          }
          fetchQueryCount(payload)
        }
      }
      if (!isEdit) {
        setQuery({})
      }
    }, [isEdit, data, canEditGroups])
    const confirmDeleteDialog = useRef()

    const dismiss = () => {
      modalRef.current.dismiss()
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
      resetValues,
    }))
    const countries = admin.registeredCountries.map((item) => item.code)
    const customLabels = admin.registeredCountries.reduce((labels, item) => {
      labels[item.code] = item.name
      return labels
    }, {})

    useEffect(() => {
      if (canEditGroups) {
        if (admin.registeredCountries.length > 1) {
          setCountryInputFilled(true)
          if (isEdit) {
            setSelected(data.countryCode)
          } else {
            setSelected(admin.registeredCountries[0].code)
            setCountry(customLabels[selected])
            fetchQueryCount({
              query: JSON.stringify({}),
              country: admin.registeredCountries[0].code,
            })
          }
        }
        if (admin.registeredCountries) {
          if (isEdit) {
            setSelected(data.countryCode)
            setPrefix(data.countryCode + '-')
          } else {
            setSelected(admin.registeredCountries[0].code)
            setPrefix(admin.registeredCountries[0].code + '-')
            setCountry(customLabels[selected])
            setQuery({})
            fetchQueryCount({
              query: JSON.stringify({}),
              country: admin.registeredCountries[0].code,
            })
          }
        }
      }
    }, [admin, data, canEditGroups])

    useEffect(() => {
      setPrefix(selected + '-')
      setCountry(customLabels[selected])

      if (selected && canEditGroups) {
        fetchQueryCount({
          query:
            query && Object.keys(query).length > 0
              ? JSON.stringify(query)
              : '{}',
          country: selected,
        })
      }
    }, [selected, canEditGroups])
    const editGroupHandler = () => {
      if (!query || Object.keys(query).length === 0) {
        setQuery({})
      }

      let processedQuery = { ...query }
      processedQuery = processDates(processedQuery)

      const group = {
        query:
          processedQuery && Object.keys(processedQuery).length > 0
            ? JSON.stringify(processedQuery)
            : '{}',
      }

      editGroup(data.id, group)
    }
    const createGroupHandler = () => {
      if (!query || Object.keys(query).length === 0) {
        setQuery({})
      }

      let processedQuery = { ...query }
      processedQuery = processDates(processedQuery)
      const group = {
        name: name,
        query:
          processedQuery && Object.keys(processedQuery).length > 0
            ? JSON.stringify(processedQuery)
            : '{}',
        country: selected,
      }
      createGroup(group)
    }
    const [selectedRow, setSelectedRow] = useState()

    useEffect(() => {
      if (deleteGroupRequestState === RequestState.SUCCEEDED) {
        confirmDeleteDialog?.current?.dismiss()
        modalRef.current.dismiss()

        setSelectedRow()
      }
    }, [deleteGroupRequestState])
    const onDeleteClickHandler = () => {
      setSelectedRow(data.id)
      confirmDeleteDialog.current.show()
    }
    const handleQueryChange = (newQuery) => {
      let currentQuery
      if (isEdit) currentQuery = formatQuery(data.groupQuery)

      const queryString =
        !newQuery || Object.keys(newQuery).length === 0
          ? JSON.stringify({})
          : JSON.stringify(newQuery)

      if (isEdit) {
        const currentQueryString =
          !currentQuery || Object.keys(currentQuery).length === 0
            ? JSON.stringify({})
            : JSON.stringify(currentQuery)
        if (queryString !== currentQueryString) {
          setCanSubmit(true)
        } else {
          setCanSubmit(false)
        }
      }
      let processedQuery = { ...newQuery }
      processedQuery = processDates(processedQuery)
      setQuery(newQuery)
      fetchQueryCount({ query: queryString, country: selected })
    }
    useEffect(() => {
      if (invalidId) {
        setQueryError(t('PushNotificationsPanel.invalidIdText'))
      } else setQueryError('')
    }, [invalidId])
    useEffect(() => {
      if (!isEdit) {
        if (name === '' || name.length < 3) {
          setCanSubmit(false)
        } else {
          setCanSubmit(true)
        }
      }
    }, [isEdit, name])

    const resetValues = () => {
      if (canEditGroups) {
        setCanSubmit(false)
        setInvalidId(false)
        setName('')
        setQuery('')
        setSelected(admin.registeredCountries[0].code)
        setPrefix(admin.registeredCountries[0].code + '-')
        setCountry(customLabels[selected])
        setQueryError('')
        fetchQueryCount({
          query: JSON.stringify({}),
          country: admin.registeredCountries[0].code,
        })
      }
    }

    useEffect(() => {
      if (
        createGroupRequestState === RequestState.SUCCEEDED ||
        (editGroupRequestState === RequestState.SUCCEEDED && isEdit)
      ) {
        setName('')
        setCountry('')
        setQuery('')
        modalRef.current.dismiss()
      }
    }, [createGroupRequestState, editGroupRequestState])
    return (
      <IFModal
        title={title}
        ref={modalRef}
        maxWidth={100}
        dialogContentStyle={{ height: '550px', width: '700px' }}
      >
        <div className={Styles.Inputs}>
          {countryInputFilled && (
            <FormGroup>
              <label htmlFor="country">{t('VouchersPage.Country')}</label>
              <div>
                <ReactFlagsSelect
                  countries={countries}
                  customLabels={customLabels}
                  showSelectedLabel={true}
                  selectedSize={14}
                  optionsSize={14}
                  fullWidth={true}
                  selected={selected}
                  onSelect={(code) => {
                    setSelected(code)
                    setPrefix(code + '-')
                    setCountry(customLabels[code])
                    fetchQueryCount({
                      query:
                        query === '' ||
                        !query ||
                        Object.keys(query).length === 0
                          ? JSON.stringify({})
                          : query,
                      country: code,
                    })
                  }}
                  selectButtonClassName={Styles.ReactFlagsSelect}
                  disabled={isEdit}
                />
              </div>
            </FormGroup>
          )}

          <IFText
            loadSkeleton={false}
            skeletonWidth={'7rem'}
            className={Styles.InputTitle}
          >
            {t('PushNotificationsPanel.GroupsTableColumns.Name')}
          </IFText>
          {false ? (
            <div className={Styles.Skeleton}>
              <IFSkeleton variant="rectangular" height={'30px'} />
            </div>
          ) : (
            <CssTextField
              variant="outlined"
              value={name}
              inputProps={{
                maxLength: 30,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '14px',
                        fontFamily: 'ProximaNova',
                        color: 'black',
                      },
                    }}
                  >
                    {prefix}
                  </InputAdornment>
                ),
                sx: {
                  fontSize: '14px',
                  fontFamily: 'ProximaNova',
                },
              }}
              onChange={(e) => {
                setName(e.target.value)
                e.target.value.length === 0
                  ? setNameError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setNameError(t('PushNotificationsPanel.minName'))
                  : setNameError('')

                setCanSubmit(e.target.value.length > 2)
              }}
              onBlur={(e) => {
                setName(e.target.value)
                e.target.value.length === 0
                  ? setNameError(t('PushNotificationsPanel.Required'))
                  : e.target.value.length < 3 && e.target.value.length !== 0
                  ? setNameError(t('PushNotificationsPanel.minName'))
                  : setNameError('')
                setCanSubmit(e.target.value.length > 2)
              }}
              disabled={isEdit}
            />
          )}
          <div className={Styles.ErrorContainer}>
            {nameError.length > 0 ? (
              <IFText style={{ color: Colors.red }}>{nameError}</IFText>
            ) : null}
          </div>

          <div className={Styles.Builder}>
            <IFText
              loadSkeleton={false}
              skeletonWidth={'7rem'}
              className={Styles.InputTitle}
            >
              {t('PushNotificationsPanel.GroupsTableColumns.Query')}
            </IFText>
            {false ? (
              <div className={Styles.Skeleton}>
                <IFSkeleton variant="rectangular" height={'30px'} />
              </div>
            ) : (
              <div className={Styles.Scrollable}>
                <QueryBuilder
                  config={config}
                  initTree={query}
                  onQueryChange={handleQueryChange}
                />
              </div>
            )}
            <div className={Styles.ErrorContainer}>
              {(queryError || invalidId) && (
                <IFText style={{ color: Colors.red, padding: '8px' }}>
                  {queryError}
                </IFText>
              )}
            </div>
          </div>
        </div>
        <div className={Styles.ButtonWrapper}>
          <div className={Styles.QueryCount}>
            <IFText className={Styles.InputTitle}>
              {`The query matches ${
                invalidId || queryError ? 0 : queryCount
              } users`}
            </IFText>
            {fetchQueryCountRequestState === RequestState.LOADING && (
              <IFLoadingIndicator size={'2em'} />
            )}
          </div>
          <div className={Styles.ButtonPlacement}>
            {isEdit && (
              <IFButton
                text={t('PushNotificationsPanel.deleteButton')}
                color={Colors.red}
                isFill={true}
                className={Styles.Button}
                onClick={onDeleteClickHandler}
                isLoading={deleteGroupRequestState === RequestState.LOADING}
              />
            )}
            <IFButton
              text={
                isEdit ? (
                  <IFText>{t('PushNotificationsPanel.editButton')}</IFText>
                ) : (
                  <IFText>{t('PushNotificationsPanel.saveButton')}</IFText>
                )
              }
              isDead={
                !canSubmit ||
                fetchQueryCountRequestState === RequestState.LOADING ||
                queryError !== '' ||
                invalidId
              }
              color={Colors.primary}
              isFill={true}
              className={Styles.Button}
              onClick={isEdit ? editGroupHandler : createGroupHandler}
              isLoading={
                isEdit
                  ? editGroupRequestState === RequestState.LOADING
                  : createGroupRequestState === RequestState.LOADING
              }
            />
          </div>
        </div>
        <IFDialog
          ref={confirmDeleteDialog}
          open={false}
          maxWidth={'xs'}
          fullWidth={true}
          title={t('PushNotificationsPanel.groupDialogTitle')}
          bodyText={t('PushNotificationsPanel.deleteGroupText')}
          buttonAcceptonClick={() => {
            deleteGroup(selectedRow)
          }}
          buttonCancelColor={Colors.UserPageCancel}
          buttonAcceptColor={Colors.UserPageDeactivateButton}
          buttonAcceptText={t('PushNotificationsPanel.confirmCancel')}
        />
      </IFModal>
    )
  },
)
CreateNotificationGroupModal.propTypes = {
  isEdit: PropTypes.bool,
  admin: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.object,
  adminRole: PropTypes.object,
}

function mapDispatchToProps(dispatch) {
  return {
    editGroup: (id, payload) =>
      dispatch(NotificationActions.editGroup(id, payload)),
    createGroup: (payload) =>
      dispatch(NotificationActions.createGroup(payload)),
    deleteGroup: (id) => dispatch(NotificationActions.deleteGroup(id)),
    fetchQueryCount: (payload) =>
      dispatch(NotificationActions.fetchQueryCount(payload)),
    setInvalidId: (bool) => dispatch(NotificationActions.setInvalidId(bool)),
  }
}

const mapStateToProps = (state) => ({
  editGroupRequestState: NotificationSelectors.getEditGroupRequestState(state),
  createGroupRequestState:
    NotificationSelectors.getCreateGroupRequestState(state),
  deleteGroupRequestState:
    NotificationSelectors.getDeleteGroupRequestState(state),
  queryCount: NotificationSelectors.getQueryCount(state),
  fetchQueryCountRequestState:
    NotificationSelectors.getQueryCountRequestState(state),
  invalidId: NotificationSelectors.getInvalidId(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CreateNotificationGroupModal)
